import { render, staticRenderFns } from "./BackOffice.vue?vue&type=template&id=4f5f24d2&scoped=true"
import script from "./BackOffice.vue?vue&type=script&lang=js"
export * from "./BackOffice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5f24d2",
  null
  
)

export default component.exports