<template>
	<table-ticket
		:isAdmin="isAdmin"
		:tickets="lstTickets"
		:isLoading="isLoading"
		:refreshData="refreshData"
		title="Back Office"
	/>
</template>
<script>
import { getTicketsByOffice } from "./../services/service";
import { TableTicket } from "@advise/styleguide";
export default {
	components: {
		TableTicket,
	},
	data() {
		return {
			isLoading: false,
			lstTickets: [],
			isAdmin: true,
		};
	},
	methods: {
		async refreshData() {
			this.isLoading = true;
			// const clientId = sessionStorage.getItem("client_id");
			this.lstTickets = await getTicketsByOffice();
			this.isLoading = false;
		},
	},
	async created() {
		await this.refreshData();
	},
};
</script>
<style scoped></style>
